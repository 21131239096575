<template>
  <footer class="footer-area footer-style-01 bg_color--6">
    <div class="im-call-to-action-area ptb--70 im-separator">
      <v-container>
        <v-row class="align-center">
          <v-col xl="6" lg="8" md="6" sm="12" cols="12">
            <div class="inner">
              <h2 class="white--text mb--0">
                Enough Talk, Let's Have a Look Together
              </h2>
            </div>
          </v-col>
          <v-col lg="4" offset-xl="2" md="6" sm="12" cols="12">
            <div
              class="text-left call-to-cation-tbn text-md-right mt_md--20 mt_sm--20"
            >
              <a class="btn-default btn-large btn-border btn-opacity"
                href="mailto:info@data-masher.com"
                >Request a demo</a
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="footer-wrapper ptb--70">
      <v-container class="container">
        <v-row>
          <v-col lg="4" md="4" sm="6" cols="12">
            <div class="ft-text">
              <div class="logo">
                <a href="/"><img :src="logo" alt="brand image"/></a>
              </div>
              <p>
                Copyright © {{ new Date().getFullYear() }}
                <a href="mailto:info@data-masher.com">data-masher.com</a>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </footer>
</template>

<script>
  export default {
    data() {
      return {
        logo: require("../../assets/images/dmlogo/logo.png"),
        socialList: [
          {
            icon: "fa-facebook-f",
            url: "https://www.facebook.com/",
          },
          {
            icon: "fa-linkedin-in",
            url: "https://www.linkedin.com/",
          },
          {
            icon: "fa-twitter",
            url: "https://twitter.com/",
          },
          {
            icon: "fa-instagram",
            url: "https://www.instagram.com/",
          },
        ],
      };
    },
  };
</script>
