<template>
  <v-container>
    <v-row align="center" class="row--35">
      <v-col lg="5" md="5" cols="12">
        <div class="thumbnail">
          <slot name="thum-img"></slot>
        </div>
      </v-col>
      <v-col lg="7" md="7" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <div class="icon" v-html="iconSvg(icon)"></div>
            <h2 class="heading-title">About Data Masher.</h2>
            <p class="description">
              Cloud-based platform integrating customer’s own analytics and data, while utilizing their existing infrastructure.
            </p>
            <p class="description">
              Bridging the gap between various analytical and data visualization platforms and enabling new features which directly interact with their data.
            </p>
            <p class="description">
              Maximizing value of customer’s existing analytics while avoiding reworks, data duplicates, or any inefficiently spent time.
            </p>
            <div class="purchase-btn">
              <a class="btn-transparent" href="mailto:info@data-masher.com"
                >Request a demo</a
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        icon: "send",
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
