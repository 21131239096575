<template>
  <v-row class="mt--30">
    <!-- Start Single Portfolio  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(item, i) in portfolioContent"
      :key="i"
    >
      <div class="im_portfolio">
        <div class="thumbnail_inner">
          <div class="thumbnail">
            <img class="w-100" :src="item.src" alt="Portfolio Images" />
          </div>
        </div>
        <div class="content">
          <div class="inner">
            <div class="portfolio_heading">
              <h4 class="heading-title">
                {{item.title }}
              </h4>
            </div>
            <div class="portfolio_hover">
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        portfolioContent: [
          {
            src: require("../../assets/images/portfolio/cloud.jpg"),
            title: "Cloud Based",
            description:
              "SAAS or On-Premise.",
          },
          {
            src: require("../../assets/images/portfolio/config.jpg"),
            title: "Configurable",
            description:
              "Projects and Business Areas defined through configuration.",
          },
          {
            src: require("../../assets/images/portfolio/security.jpg"),
            title: "High Security",
            description:
              "Security in underlying apps is not overcome.",
          },
        ],
      };
    },
  };
</script>
