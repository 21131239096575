<template>
  <v-row>
    <!-- Start Single Address  -->
    <v-col
      lg="6"
      md="6"
      sm="8"
      cols="12"
      v-for="(address, i) in addressContent"
      :key="i"
    >
      <div class="rn-address" :class="address.isAddress">
        <div class="icon" v-html="iconSvg(address.icon)"></div>
        <div class="inner">
          <h4 class="heading-title" style="color: #0a0a0a">{{ address.title }}</h4>
          <p>
            <a :href="address.to1">{{ address.info1 }}</a>
          </p>
        </div>
      </div>
    </v-col>
    <!-- End Single Address  -->
  </v-row>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        addressContent: [
          {
            icon: "mail",
            title: "Our Email Address",
            info1: "info@data-masher.com",
            to1: "mailto:info@data-masher.com",
          },
          {
            icon: "map",
            title: "Our Location",
            info1: "Varšavská 715/36, 120 00 Praha, Czechia",
            isAddress: "addres-last",
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>

<style lang="scss">
  .addres-last {
    p {
      pointer-events: none;
    }
  }
</style>
