import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'
import HomePage from '../views/all-home-version/MainDemoDark.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: HomePage,
        meta: {
            title: 'Data Masher - Data at Your Fingertips'
        }
    },
    {
        path: '*',
        component: Missing,
        meta: {
            title: '404 - Data Masher'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router