<template>
  <div class="active-dark">
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <div id="home" class="slider-wrapper">
      <SliderOne></SliderOne>
    </div>
    <!-- End Slider Area -->

    <!-- Start About Area -->
    <div id="about" class="about-area ptb--120 bg_color--1">
      <div class="about-wrapper">
        <About>
          <img
            slot="thum-img"
            class="w-100"
            src="../../assets/images/about/about-dm.png"
            alt="About Images"
          />
        </About>
      </div>
    </div>
    <!-- End About Area -->

    <!-- Start Service Area -->
    <div id="services" class="service-area ptb--120 bg_color--5">
      <v-container>
        <v-row class="mb--30">
          <v-col cols="12">
            <div class="text-center section-title">
              <span class="subtitle">One-Stop-Shop</span>
              <h2 class="heading-title">One interface, Various platforms.</h2>
              <p class="description">
                Easy access to the data through ONE interface within a few clicks,
                <br />
                regardless of the platform.
              </p>
            </div>
          </v-col>
        </v-row>
        <ServiceTwo />
      </v-container>
    </div>
    <!-- End Service Area -->

    <!-- Start Portfolio Area -->
    <div class="portfolio-area ptb--120 bg_color--1">
      <div class="portfolio-wrapper">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="text-center section-title">
                <span class="subtitle">Technical Characteristics</span>
                <h2 class="heading-title">Perfectly Tailored</h2>
                <p class="description">
                  Every project can have its own layout and configuration.
                </p>
              </div>
            </v-col>
          </v-row>
          <Portfolio />
        </v-container>
      </div>
    </div>
    <!-- End Portfolio Area -->

    <!-- Start Contact Address Area  -->
    <div id="contact" class="rn-contact-top-area ptb--120 bg_color--5">
      <v-container>
        <div class="text-center section-title mb--30">
          <span class="subtitle">Our contact address</span>
          <h2 class="heading-title">Quick Contact Address</h2>
        </div>
        <ContactAddress />
      </v-container>
    </div>
    <!-- End Contact Address Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
  import Header from "../../components/header/Header";
  import SliderOne from "../../components/slider/SliderOne";
  import About from "../../components/about/About";
  import ServiceTwo from "../../components/service/ServiceTwo";
  import Portfolio from "../../components/portfolio/Portfolio";
  import CounterOne from "../../components/counter/CounterOne";
  import Testimonial from "../../components/testimonial/Testimonial";
  import Blog from "../../components/blog/Blog";
  import Brand from "../../components/brand/Brand";
  import Footer from "../../components/footer/Footer";
  import ContactAddress from "../../components/contact/ContactAddress";

  export default {
    components: {
      Header,
      SliderOne,
      About,
      ServiceTwo,
      Portfolio,
      CounterOne,
      Testimonial,
      Blog,
      Brand,
      Footer,
      ContactAddress,
    },
    data() {
      return {
        logo: require("../../assets/images/dmlogo/logo.png"),
      };
    },
  };
</script>
