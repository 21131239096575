<template>
  <!-- Start Slider Area  -->
  <div class="rn-slider-area demo_slider-area">
    <!-- Start Single Slide  -->
    <div
      class="slide slide-style-1 slider-fixed--height d-flex align-center bg_image bg_image--1"
      data-black-overlay="8"
    >
      <v-container class="position-relative">
        <v-row>
          <v-col cols="12">
            <div class="inner" style="height: 300px">
              <vue-typed-js :strings="['Data at Your Fingertips.']">
                <h1 class="heading-title theme-gradient">
                  <span class="typing"></span>
                </h1>
              </vue-typed-js>
            </div>
          </v-col>
        </v-row>
        <!-- End .row -->
        <!-- Start Service Area  -->
        <div class="service-wrapper service-white">
          <ServiceOne />
        </div>
        <!-- End Service Area  -->
      </v-container>
    </div>
    <!-- End Single Slide  -->
  </div>
  <!-- End Slider Area  -->
</template>

<script>
  import ServiceOne from "../../components/service/ServiceOne";
  import {VueTypedJs} from "vue-typed-js";

  export default {
    components: {
      ServiceOne,
      VueTypedJs
    },
  };
</script>

<style lang="scss">
  .demo_slider-area {
    .slide.slide-style-1 .inner {
      padding-top: 70px;
    }
    @media only screen and (max-width: 991px) {
      .slider-fixed--height {
        padding-bottom: 70px;
      }
      .service-white .service__style--1 .content p {
        padding: 0 30px;
      }
    }
  }
  .slider-wrapper {
    .demo_slider-area {
      .service-wrapper {
        margin-bottom: 0;
      }
    }
  }
</style>
