<template>
  <!-- Start Service Area  -->
  <v-row class="service-main-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="6"
      sm="6"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="text-left service service__style--2">
        <div class="service">
          <div class="icon" v-html="iconSvg(service.icon)"></div>
          <div class="content">
            <h3 class="heading-title">{{ service.title }}</h3>
            <p>
              {{ service.desc }}
            </p>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "cast",
            title: "Netflix Style Look & Feel",
            desc: `Clean design, intuitively placed controls and navigation make your Insights always only a few clicks away.`,
          },
          {
            icon: "home",
            title: "Project Hub",
            desc: `A one-stop-shop for an entire project or business area, allowing users to access all project-related information in one place.`,
          },
          {
            icon: "activity",
            title: "More Efficient Business Process",
            desc: `NO duplicities, reworks, code generation, or wasted time! Focus on actions, not preparing repetitive reports.`,
          },
          {
            icon: "bar-chart-2",
            title: "Interactive Consolidated KPIs",
            desc: `Get all your important insights in one view from various existing apps without the need to duplicate the original assets, even across different platforms.`,
          },
          {
            icon: "message-square",
            title: "Collaborative Data Storytelling",
            desc: ` Empower collaboration based on any data insights, allowing teams to work together and make informed decisions that drive business growth.`,
          },
          {
            icon: "zap",
            title: "Break the Barriers",
            desc: ` Additional features enhancing the analytics, even though the native platforms might not support them.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
